/* src/index.css */

/* Tailwind base styles (if using Tailwind CSS) */
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  overscroll-behavior: none;
}


/* Custom Animations */
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.noscroll {
  overflow: hidden; /* Disable scrollbars */
  touch-action: none; /* Disable touch scroll on mobile */
  -ms-touch-action: none; /* For older IE/Edge versions */
}



/* Attack Animation */
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.animate-swing {
  animation: swing 0.5s ease-in-out infinite;
  /* Add glow border */
  box-shadow: 0 0 8px rgba(255, 215, 0, 0.8);
}

/* Retreat Animation */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-1px);
  }
  40% {
    transform: translateX(1px);
  }
  60% {
    transform: translateX(-1px);
  }
  80% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-shake {
  animation: shake 0.5s ease-in-out infinite;
}

/* Celebrate Animation */
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-jump {
  animation: jump 0.8s ease-out infinite;
}

/* Dead Animation */
@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

.animate-fade-out {
  animation: fade-out 1s forwards;
}

/* Battlefield Styling */
.battlefield {
  position: relative;
  width: 100%;
  height: 75vh; /* Adjust as needed */
  background-color: #f0f0f0;
}

/* For desktop screens, increase the height to 80% of the viewport height */
@media (min-width: 1024px) {
  .battlefield {
    height: 80vh; /* 80% of the viewport height for desktop screens */
  }
}

/* Soldier Styling */
.soldier {
  position: absolute;
}

/* Body Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
